
import { defineComponent, inject, onMounted, Ref, ref } from "vue";
import Camera from "@/components/Camera.vue";
import router from "@/router";
import globalstate from "@/statemanager/globalstate";
import { SwitchHorizontalIcon } from "@heroicons/vue/solid";
import ClarityImageGalleryLine from "@/components/svgs/gallery.vue";
export default defineComponent({
  name: "App",
  components: {
    Camera,
    SwitchHorizontalIcon,
    ClarityImageGalleryLine,
  },
  setup() {
    const camera = ref<InstanceType<typeof Camera>>();
    const cameras: Ref<MediaDeviceInfo[]> = ref([]);
    const store = inject("store") as typeof globalstate;
    let cameraIndex = ref<number>(0);
    onMounted(async () => {
      if (!camera.value) return;
      try {
        const devices: MediaDeviceInfo[] = await camera.value.devices([
          "videoinput",
        ]);
        cameras.value = devices;
        console.log(devices);
      } catch (e) {
        console.log(e);
      }
    });
    const start = () => camera.value?.start();
    const stop = () => camera.value?.stop();
    const pause = () => camera.value?.pause();
    const resume = () => camera.value?.resume();
    const currentSnapshot = ref();
    const fileChange = (e: Event) => {
      if (
        e.target !== null &&
        e.target &&
        (e.target as HTMLInputElement).files !== null
      ) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        let file = (e.target as HTMLInputElement).files![0];
        if (file) {
          store().setBlob(file);
          router.push({ name: "CropImage" });
        }
      }
    };

    const snapshot = async () => {
      const blob = await camera.value?.snapshot({
        width: 1080,
        height: 1080,
      });
      if (blob) {
        store().setBlob(blob);
      }
      //currentSnapshot.value = URL.createObjectURL(blob);
      router.push({ name: "Poster" });
    };
    const logEvent = (name: string) => console.log(name);

    const changeCamera = (event: Event) => {
      const target = event.target as HTMLSelectElement;
      camera.value?.changeCamera(target.value);
    };
    const toggleCamera = () => {
      if (cameraIndex.value < cameras.value.length - 1) {
        cameraIndex.value += 1;
      } else {
        cameraIndex.value = 0;
      }
      camera.value?.changeCamera(cameras.value[cameraIndex.value].deviceId);
    };

    return {
      camera,
      start,
      stop,
      pause,
      resume,
      logEvent,
      snapshot,
      currentSnapshot,
      cameras,
      changeCamera,
      cameraIndex,
      toggleCamera,
      fileChange,
    };
  },
});
