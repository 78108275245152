
import { jobApiService } from "@/api/mediaPosts.api";
import MediaPost from "@/models/mediaPostModel";
import PostDetails from "../components/PostDetails.vue";
// import { useAuth0 } from "@/services/auth0-plugin";
import {
  watch,
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  ref,
} from "vue";
import useDateTimeFormating from "../composables/dateFormatter";
import User from "@/models/userModel";
import { useAuth } from "@/auth/useAuthService";
import WhhCommunity from "@/components/svgs/community.vue";

export default defineComponent({
  components: {
    PostDetails,
    WhhCommunity,
  },
  setup() {
    const { formatDateTime } = useDateTimeFormating();
    const Images = ref<Array<MediaPost>>([]);
    const {
      getTokenSilently,
      loginWithRedirect,
      logout,
      isAuthenticated,
      loading,
      user,
    } = useAuth();
    let Active = ref<number>(0);
    let currentIndex = ref<number>(0);
    let accessToken = "";

    watch(loading, (currentValue, oldValue) => {
      if (currentValue === false && oldValue === true) {
        getPosts();
      }
    });

    const getAccessToken = async () => {
      if (!loading.value) {
        console.log("get token");
        return await getTokenSilently();
      } else return "";
    };
    const getPosts = async () => {
      if (!loading.value) {
        let token = await getAccessToken();
        accessToken = token !== null ? token : "";
        let current = await jobApiService.posts(accessToken);
        let newPosts = current.filter(
          (x) => !Images.value.find((y) => x.postId === y.postId)
        );
        console.log(newPosts);
        if (newPosts.length > 0) {
          currentIndex.value = 0;
          Images.value = await jobApiService.posts(accessToken);
        }
      }
    };

    const next = () => {
      currentIndex.value += 1;
    };
    const prev = () => {
      currentIndex.value -= 1;
    };
    const currentImg: ComputedRef<string> = computed((): string => {
      console.log(Images.value);
      if (Images.value.length > 0) {
        return Images.value[Math.abs(currentIndex.value) % Images.value.length]
          .mediaUri;
      } else {
        return "";
      }
    });
    const currentMediaPost: ComputedRef<MediaPost> = computed((): MediaPost => {
      if (Images.value.length > 0) {
        return Images.value[Math.abs(currentIndex.value) % Images.value.length];
      } else {
        let user = new User();
        let mediaPost = new MediaPost();
        mediaPost.postedBy = user;
        return mediaPost;
      }
    });
    onMounted(async () => {
      await getPosts();
      console.log(Images.value);
      setInterval(() => {
        next();
      }, 40000);
      setInterval(() => {
        getPosts();
      }, 60000);
    });
    return {
      getPosts,
      currentImg,
      currentMediaPost,
      Images,
      Active,
      next,
      prev,
      currentIndex,
      formatDateTime,
      loginWithRedirect,
      logout,
      isAuthenticated,
      user,
    };
  },
});
