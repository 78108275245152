
import router from "@/router";
import globalstate from "@/statemanager/globalstate";
import { computed, ComputedRef, defineComponent, inject, ref } from "vue";
import { Cropper, CropperResult } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default defineComponent({
  components: {
    Cropper,
  },
  setup() {
    const store = inject("store") as typeof globalstate;
    const refCropper = ref<typeof Cropper>();
    let cordinates = {
      width: 0,
      height: 0,
      left: 0,
      top: 0,
    };
    const cropAndForward = () => {
      console.log(refCropper);
      const res: CropperResult = refCropper.value?.getResult();
      cordinates = res.coordinates;
      console.log(cordinates);
      if (res.canvas) {
        res.canvas.toBlob(
          (blob) => {
            if (blob) store().setBlob(blob);
          },
          "image/jpg",
          "0.9"
        );
        router.push({ name: "Poster" });
      }
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image, that can be used
      // as src for <img/> to preview result
      //this.image = canvas.toDataURL();
    };
    const capturedImage: ComputedRef = computed((): string => {
      return URL.createObjectURL(store().imageBlob.value);
    });
    return { capturedImage, cropAndForward, refCropper };
  },
});
