
import useInitials from "@/composables/useInitials";
import MediaPost from "@/models/mediaPostModel";
import dayjs from "dayjs";
import { computed, ComputedRef, defineComponent, ref, Ref } from "vue";
import useDateTimeFormating from "../composables/dateFormatter";
import { useTimeAgo, UseTimeAgoMessages } from "@vueuse/core";

export default defineComponent({
  props: {
    mediaPost: {
      type: MediaPost,
      required: true,
    },
  },
  setup(props) {
    const { formatDateTime } = useDateTimeFormating();
    const { getInitials } = useInitials();

    const isNew: ComputedRef<boolean> = computed((): boolean => {
      return (
        dayjs(props.mediaPost.postedDateTime).add(1, "days").toDate() >=
        dayjs().toDate()
      );
    });

    const NORSKE_MESSAGES: UseTimeAgoMessages = {
      justNow: "akkurat nå",
      past: (n) => (n.match(/\d/) ? `${n} siden` : n),
      future: (n) => (n.match(/\d/) ? `om ${n}` : n),
      month: (n, past) =>
        n === 1
          ? past
            ? "forrige måned"
            : "neste måned"
          : `${n} måned${n > 1 ? "er" : ""}`,
      year: (n, past) =>
        n === 1 ? (past ? "i fjor" : "neste år") : `${n} år${n > 1 ? "" : ""}`,
      day: (n, past) =>
        n === 1
          ? past
            ? "i går"
            : "i morgen"
          : `${n} dag${n > 1 ? "er" : ""}`,
      week: (n, past) =>
        n === 1
          ? past
            ? "forrige uke"
            : "neste uker"
          : `${n} uke${n > 1 ? "r" : ""}`,
      hour: (n) => `${n} time${n > 1 ? "r" : ""}`,
      minute: (n) => `${n} minutt${n > 1 ? "er" : ""}`,
      second: (n) => `${n} sekund${n > 1 ? "er" : ""}`,
    };
    const timeAgo: ComputedRef<string> = computed((): string => {
      if (props.mediaPost && props.mediaPost.postedDateTime) {
        return useTimeAgo(new Date(props.mediaPost.postedDateTime), {
          messages: NORSKE_MESSAGES,
        }).value;
      } else {
        return "";
      }
    });

    return { formatDateTime, getInitials, isNew, timeAgo };
  },
});
