/* eslint-disable prettier/prettier */
<template>
  <svg width="1em" height="1em" viewBox="0 0 1024 1024">
    <path
      d="M512 1024q-104 0-199-40.5t-163.5-109T40.5 711T0 512t40.5-199t109-163.5T313 40.5T512 0t199 40.5t163.5 109t109 163.5t40.5 199t-40.5 199t-109 163.5t-163.5 109t-199 40.5zm0-896q-104 0-192.5 51.5t-140 140T128 512q0 146 98 256h126q40 0 68 28t28 68v26q34 6 64 6t64-6v-26q0-40 28-68t68-28h126q98-110 98-256q0-104-51.5-192.5t-140-140T512 128zm224 576q-53 0-90.5-37.5T608 576t37.5-90.5T736 448t90.5 37.5T864 576t-37.5 90.5T736 704zM512 448q-53 0-90.5-37.5T384 320t37.5-90.5T512 192t90.5 37.5T640 320t-37.5 90.5T512 448zm-64 128q0 53-37.5 90.5T320 704t-90.5-37.5T192 576t37.5-90.5T320 448t90.5 37.5T448 576z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "WhhCommunity",
};
</script>
