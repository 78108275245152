
import globalstate from "@/statemanager/globalstate";
import { computed, ComputedRef, defineComponent, inject, ref } from "vue";
import { multipartApiService } from "@/api/multipartMediaPosts.api";
import MediaPost from "@/models/mediaPostModel";
import User from "@/models/userModel";
import router from "@/router";
import { useAuth } from "@/auth/useAuthService";
import dayjs from "dayjs";

export default defineComponent({
  components: {},
  setup() {
    let saving = ref(false);
    const { getTokenSilently, isAuthenticated, loading, user } = useAuth();
    const postMessage = ref<string>("");
    const expireDays = ref<number>(4);
    const store = inject("store") as typeof globalstate;
    const capturedImage: ComputedRef = computed((): string => {
      return URL.createObjectURL(store().imageBlob.value);
    });

    const getDays = () => {
      let days = 1;
      switch (expireDays.value) {
        case 1:
          days = 1;
          break;
        case 2:
          days = 2;
          break;
        case 3:
          days = 4;
          break;
        case 4:
          days = 7;
          break;
        case 5:
          days = 14;
          break;
        case 6:
          days = 31;
          break;
        default:
          days = 1;
          break;
      }

      return days;
    };
    const postIt = async () => {
      if (saving.value === true) return;
      saving.value = true;
      let mPost: MediaPost = new MediaPost();
      let userModel: User = new User();
      console.log(dayjs().add(getDays()).toISOString());
      if (user.value) {
        userModel.username = user.value.name as string;
        userModel.id = user.value.sub as string;
        mPost.postMessage = postMessage.value;
        mPost.postedBy = userModel;
        mPost.contentType = "image/jpg";
        mPost.expireDateTime = dayjs().add(getDays(), "days").toISOString();
        //mPost.expireDateTime =
        mPost.fileType = "jpg";

        let res = await multipartApiService.uploadPost(
          store().imageBlob.value,
          mPost,
          await getTokenSilently()
        );
        saving.value = false;
        if (res.postId) {
          router.push({ name: "Home" });
        }
      }
    };
    return { capturedImage, postMessage, postIt, saving, expireDays };
  },
});
