import { MultipartAbstractApiService } from "./multipartAbstractApiService";
// import User from "@/models/userModel";
import MediaPost from "@/models/mediaPostModel";

class MultipartApiService extends MultipartAbstractApiService {
  public constructor() {
    super("");
  }

  public uploadPost(
    image: Blob,
    post: MediaPost,
    accessToken: string
  ): Promise<MediaPost> {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    const data = new FormData();
    data.append("Media", image);
    data.append("MediaPost", JSON.stringify(post));
    return this.http
      .post("", data, config)
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }

  public deletePost(id: string): Promise<void> {
    return this.http
      .delete(`/${id}`)
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }
}

export const multipartApiService: MultipartApiService =
  new MultipartApiService();
