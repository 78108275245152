import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51088fc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "camera-container" }
const _hoisted_2 = {
  autoplay: "",
  muted: "",
  playsinline: "",
  ref: "video",
  id: "video"
}
const _hoisted_3 = { id: "slot-container" }
const _hoisted_4 = {
  ref: "canvas",
  id: "canvas"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("video", _hoisted_2, null, 512),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _createElementVNode("canvas", _hoisted_4, null, 512)
  ], 64))
}