<template>
  <svg width="1em" height="1em" viewBox="0 0 36 36">
    <path
      d="M32.12 10H3.88A1.88 1.88 0 0 0 2 11.88v18.24A1.88 1.88 0 0 0 3.88 32h28.24A1.88 1.88 0 0 0 34 30.12V11.88A1.88 1.88 0 0 0 32.12 10zM32 30H4V12h28z"
      class="clr-i-outline clr-i-outline-path-1"
      fill="currentColor"
    ></path>
    <path
      d="M8.56 19.45a3 3 0 1 0-3-3a3 3 0 0 0 3 3zm0-4.6A1.6 1.6 0 1 1 7 16.45a1.6 1.6 0 0 1 1.56-1.6z"
      class="clr-i-outline clr-i-outline-path-2"
      fill="currentColor"
    ></path>
    <path
      d="M7.9 28l6-6l3.18 3.18L14.26 28h2l7.46-7.46L30 26.77v-2L24.2 19a.71.71 0 0 0-1 0l-5.16 5.16l-3.67-3.66a.71.71 0 0 0-1 0L5.92 28z"
      class="clr-i-outline clr-i-outline-path-3"
      fill="currentColor"
    ></path>
    <path
      d="M30.14 3a1 1 0 0 0-1-1h-22a1 1 0 0 0-1 1v1h24z"
      class="clr-i-outline clr-i-outline-path-4"
      fill="currentColor"
    ></path>
    <path
      d="M32.12 7a1 1 0 0 0-1-1h-26a1 1 0 0 0-1 1v1h28z"
      class="clr-i-outline clr-i-outline-path-5"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "ClarityImageGalleryLine",
};
</script>
