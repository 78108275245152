import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cropper = _resolveComponent("cropper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_cropper, {
      ref: "refCropper",
      id: "refCropper",
      src: _ctx.capturedImage,
      "stencil-props": {
        aspectRatio: 1 / 1,
        movable: true,
        resizable: true,
      },
      canvas: {
        minHeight: 1000,
        minWidth: 1000,
        maxHeight: 2048,
        maxWidth: 2048,
      }
    }, null, 8, ["src"]),
    _createElementVNode("button", {
      class: "absolute right-0 bottom-0 ml-3 inline-flex mb-2 mr-2 items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cropAndForward && _ctx.cropAndForward(...args)))
    }, " Videre ")
  ]))
}