import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "m-2 flex content-center space-x-2" }
const _hoisted_3 = { class: "w-12 h-12 bg-green-700 text-green-100 font-ubuntu font-medium rounded-full flex justify-center items-center text-center p-5" }
const _hoisted_4 = { class: "font-ubuntu font-semibold text-lg flex items-center pl-4" }
const _hoisted_5 = {
  key: 0,
  class: "inline-flex items-center px-2.5 py-0.5 font-semibold rounded-md text-lg justify-center bg-yellow-500 text-gray-900"
}
const _hoisted_6 = {
  key: 1,
  class: "inline-flex items-center px-2.5 py-0.5 font-semibold rounded-md border text-lg justify-center bg-white text-gray-900"
}
const _hoisted_7 = { class: "font-ubuntu text-3xl font-normal text-left p-2 whitespace-pre-line" }
const _hoisted_8 = { class: "font-ubuntu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getInitials(_ctx.mediaPost.postedBy.username)), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, _toDisplayString(_ctx.mediaPost.postedBy.username), 1)
      ]),
      (_ctx.isNew)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.timeAgo), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.timeAgo), 1))
    ]),
    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.mediaPost.postMessage), 1),
    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.formatDateTime(_ctx.mediaPost.postedDateTime)), 1)
  ]))
}